import cn from 'classnames';

import styles from './Label.module.scss';

interface LabelProps {
  children?: string;
  sublabel?: string;
  className?: string;
  htmlFor?: string;
}

export function Label({ children, sublabel, className, htmlFor }: LabelProps) {
  return (
    <label htmlFor={htmlFor} className={cn(styles.container, className)}>
      <span className={styles.label}>{children}</span>
      {sublabel && <span className={styles.sublabel}>{sublabel}</span>}
    </label>
  );
}

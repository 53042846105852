import { default as NextLink } from 'next/link';
import cn from 'classnames';
import { ReactNode } from 'react';
import { isRelativeUrl } from '../utils/isRelativeUrl';

import styles from './Link.module.scss';

type LinkType = 'default' | 'icon';
interface LinkProps {
  name?: string;
  type?: LinkType;
  children: ReactNode;
  href: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  className?: string;
  reportAnalytics?: (url: string) => void;
}

export function Link({
  name,
  type,
  children,
  href,
  startIcon,
  endIcon,
  disabled,
  className,
  reportAnalytics
}: LinkProps) {
  const calculatedClassName = cn(styles.link, className, {
    [styles.icon]: type === 'icon',
    [styles.linkDisabled]: disabled
  });

  return isRelativeUrl(href) ? (
    <NextLink href={href} legacyBehavior={true}>
      <a aria-label={name} className={calculatedClassName}>
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        <span className={styles.children}>{children}</span>
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </a>
    </NextLink>
  ) : (
    <a
      className={calculatedClassName}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => (reportAnalytics ? reportAnalytics(href) : null)}
    >
      {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
      <span className={styles.children}>{children}</span>
      {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
    </a>
  );
}

import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useSession, signOut } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import { ButtonLink } from './ButtonLink';
import { Button } from './Button';
import { Link } from './Link';
import { Icon } from './Icon';
import { Title } from './Title';

import styles from './PublicNavigation.module.scss';

interface PublicNavigationProps {
  hasBackButton?: boolean;
  hasBackLink?: boolean;
  organizationLogoUrl?: string;
  organizationName?: string;
}

export function PublicNavigation({
  hasBackButton = false,
  hasBackLink = true,
  organizationLogoUrl,
  organizationName
}: PublicNavigationProps) {
  const { t } = useTranslation('common');
  const ref = useRef<HTMLDivElement | null>(null);
  const [scrollYOffset, setScrollYOffset] = useState(0);
  const { back } = useRouter();
  const { data: session } = useSession();

  useEffect(() => {
    const getScrollY = () => setScrollYOffset(window.scrollY);

    window.addEventListener('scroll', getScrollY);

    if (ref.current) {
      if (scrollYOffset > 0) {
        ref.current.style.boxShadow = '0 4px 12px rgb(16 88 128 / 50%)';
      } else {
        ref.current.style.boxShadow = 'none';
      }
    }

    return () => window.removeEventListener('scroll', getScrollY);
  }, [scrollYOffset]);

  return (
    <div ref={ref} className={styles.container}>
      <nav className={styles.navbar}>
        <div className={styles.navBackContainer}>
          {hasBackButton && (
            <div className={styles.navBackContainer}>
              <Button
                type="button"
                variant="icon"
                className={styles.navBackButton}
                onClick={() => {
                  session
                    ? signOut({
                        callbackUrl: '/auth/signin'
                      })
                    : back();
                }}
                startIcon={<Icon name="arrowLeft" />}
              >
                {t('navigation.back')}
              </Button>
            </div>
          )}

          {hasBackLink && !session && !hasBackButton && (
            <ButtonLink
              variant="default"
              href="/auth/signin"
              className={styles.navBackButton}
              iconClassName={styles.navBackIcon}
              startIcon={<Icon name="arrowLeft" />}
            >
              {t('navigation.back')}
            </ButtonLink>
          )}
        </div>
        <div className={styles.logosContainer}>
          <div className={styles.logoContainer} tabIndex={0}>
            <Link href="/auth/signin">
              <div className={styles.imgContainer}>
                <Image
                  src="/images/logo--white.svg"
                  layout="fill"
                  objectFit="contain"
                  alt={t('navigation.logo-alt')}
                />
              </div>
            </Link>
          </div>
          {organizationLogoUrl !== undefined &&
            organizationName !== undefined && (
              <>
                <Title titleTag="h1" className={styles.logoPlus}>
                  +
                </Title>
                <div className={styles.orgImgContainer}>
                  <img
                    src={organizationLogoUrl}
                    alt={organizationName}
                    className={styles.orgImg}
                  />
                </div>
              </>
            )}
        </div>
      </nav>
    </div>
  );
}

import { default as NextLink } from 'next/link';
import { ButtonVariant } from './Button';
import cn from 'classnames';
import { isRelativeUrl } from '../utils/isRelativeUrl';

import styles from './ButtonLink.module.scss';

interface ButtonLinkProps {
  href: string;
  variant: ButtonVariant;
  alternate?: boolean;
  children?: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  fileName?: string;
  className?: string;
  iconClassName?: string;
}

export function ButtonLink({
  href,
  variant,
  alternate,
  children,
  startIcon,
  endIcon,
  disabled,
  className,
  iconClassName,
  fileName
}: ButtonLinkProps) {
  const calculatedClassName = cn(
    styles.buttonLink,
    styles[`${variant}`],
    className,
    {
      [styles[`${variant}Disabled`]]: disabled,
      [styles[`${variant}Alternate`]]: alternate,
      [styles[`${variant}AlternateDisabled`]]: alternate && disabled,
      [styles.iconOnly]: !children && !endIcon
    }
  );

  return isRelativeUrl(href) ? (
    <NextLink href={href} legacyBehavior={true}>
      <a className={calculatedClassName}>
        {startIcon && (
          <span className={cn(styles.startIcon, iconClassName)}>
            {startIcon}
          </span>
        )}
        {children && <span>{children}</span>}
        {endIcon && (
          <span className={cn(styles.endIcon, iconClassName)}>{endIcon}</span>
        )}
      </a>
    </NextLink>
  ) : (
    <a
      className={calculatedClassName}
      download={fileName}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
      {children && <span>{children}</span>}
      {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
    </a>
  );
}

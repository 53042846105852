import { useState } from 'react';
import { GetServerSideProps, GetServerSidePropsResult } from 'next';
import { getSession, signIn } from 'next-auth/react';
import { SSRConfig, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import { LayoutContent } from '../../components/LayoutContent';
import { Title } from '../../components/Title';
import { Input } from '../../components/form/Input';
import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { PublicNavigation } from '../../components/PublicNavigation';
import { useRouter } from 'next/router';
import { Alert } from '../../components/Alert';

import styles from './signin.module.scss';
import { getLocaleFromContext } from '../../utils/getLocaleFromContext';

interface InputValues {
  [key: string]: string;
}

export default function Signin() {
  const { t } = useTranslation();
  const {
    query: { relocate: callbackUrl },
    push
  } = useRouter();
  const [error, setError] = useState<string>();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [isFormFocused, setIsFormFocused] = useState<boolean>(false);

  const handleInputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInputValues({
      ...inputValues,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsFormFocused(false);

    if (process.env.APP_ENV !== 'production') {
      localStorage.setItem('username', inputValues.usernameOrEmail);
    }

    const res = await signIn('credentials', {
      usernameOrEmail: inputValues.usernameOrEmail,
      password: inputValues.password,
      redirect: false,
      ...(callbackUrl && !Array.isArray(callbackUrl) && { callbackUrl })
    });

    if (!res) {
      return;
    }

    if (res.status === 401) {
      if (res.error === 'CredentialsSignin') {
        setError('invalid-credentials');
      } else {
        setError('other');
      }
    } else {
      if (res.url) {
        push(`${res.url}`);
      } else {
        callbackUrl && !Array.isArray(callbackUrl)
          ? push(callbackUrl)
          : push('/dashboard');
      }
    }
  };

  return (
    <Layout>
      <PublicNavigation hasBackLink={false} />
      <Header
        titleTag="h1"
        titleText={t('sign-in.header')}
        descriptionText={t('sign-in.description')}
      />
      <LayoutContent>
        {error && !isFormFocused && (
          <Alert type="negative">{t(`sign-in.error.${error}`)}</Alert>
        )}
        <div className={styles.container}>
          <div className={styles.formWrapper}>
            <div className={styles.form}>
              <Title
                className={styles.title}
                titleTag="h2"
                titleTheme="primary"
              >
                {t('sign-in.button')}
              </Title>
              <form
                className={styles.signInForm}
                onSubmit={handleOnSubmit}
                onFocus={() => setIsFormFocused(true)}
              >
                <Input
                  name="usernameOrEmail"
                  type="text"
                  onChange={handleInputOnChange}
                >
                  {t('form.usernameoremail')}
                </Input>
                <Input
                  name="password"
                  type="password"
                  onChange={handleInputOnChange}
                >
                  {t('form.password')}
                </Input>
                <Button
                  type="submit"
                  name="submit"
                  className={styles.buttonSignIn}
                  variant="primary"
                >
                  {t('sign-in.button')}
                </Button>
              </form>
            </div>
            <p className={styles.linkForgot}>
              <Link href="/auth/forgot-password">
                {t('form.forgot-password')}
              </Link>
            </p>
          </div>
          {/* Commented out by Kyle's request, can be added back in fututre */}
          {/* <div className={styles.formWrapper}>
            <div className={styles.form}>
              <Title
                className={styles.title}
                titleTag="h2"
                titleTheme="primary"
              >
                {t('sign-up.title')}
              </Title>
              <div className={styles.signUp}>
                <p className={styles.signUpText}>
                  <strong className={styles.signUpTextEmphasis}>
                    {t('sign-up.description-emphasis')}
                  </strong>
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </LayoutContent>
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async (
  context
): Promise<GetServerSidePropsResult<SSRConfig>> => {
  const session = await getSession(context);

  return session && session.user.id !== 'invalid'
    ? {
        redirect: {
          permanent: false,
          destination: '/dashboard'
        }
      }
    : {
        props: {
          ...(await serverSideTranslations(
            getLocaleFromContext(context).locale,
            ['common']
          ))
        }
      };
};

import cn from 'classnames';
import { Label } from './Label';

import styles from './Input.module.scss';

type InputType = 'text' | 'password' | 'email';

interface InputProps {
  name: string;
  value?: string;
  defaultValue?: string;
  children?: string;
  type: InputType;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  sublabel?: string;
}

export function Input({
  name,
  value,
  defaultValue,
  children,
  type,
  placeholder,
  onChange,
  disabled,
  error,
  className,
  sublabel
}: InputProps) {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.disabled]: disabled,
        [styles.error]: error
      })}
    >
      <Label htmlFor={name} sublabel={sublabel} className={styles.label}>
        {children}
      </Label>
      <input
        id={name}
        value={value}
        defaultValue={defaultValue}
        name={name}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        className={styles.input}
        autoComplete="on"
        data-cy={name}
      />
    </div>
  );
}

import cn from 'classnames';
import { Icon, IconName } from './Icon';

import styles from './Alert.module.scss';

type AlertSize = 'sm' | 'md';
export type AlertType =
  | 'default'
  | 'positive'
  | 'warning'
  | 'negative'
  | 'lock';

interface AlertProps {
  children: React.ReactNode;
  type?: AlertType;
  size?: AlertSize;
  className?: string;
}

function getIconName(type: string): IconName {
  switch (type) {
    case 'warning':
    case 'negative':
      return 'warning';
    case 'positive':
      return 'check';
    case 'lock':
      return 'lock';
    default:
      return 'info';
  }
}

export function Alert({
  children,
  type = 'default',
  size = 'md',
  className
}: AlertProps) {
  return (
    <div
      role="alert"
      className={cn(styles.container, className, {
        [styles[`${type}`]]: type,
        [styles[`${size}`]]: size
      })}
    >
      <span className={styles.icon}>
        <Icon name={getIconName(type)} size={size} />
      </span>
      <span className={styles.text}>{children}</span>
    </div>
  );
}

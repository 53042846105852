import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './LayoutContent.module.scss';

interface LayoutProps {
  children?: ReactNode;
  className?: string;
  clouds?: boolean;
}

export function LayoutContent({
  children,
  className,
  clouds = true
}: LayoutProps) {
  return (
    <section
      className={cn({
        [styles.content]: clouds
      })}
    >
      <div className={cn(styles.container, className)}>{children}</div>
    </section>
  );
}

import styles from './Footer.module.scss';
import Logo from '../../public/images/logo.svg';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';

interface FooterProps {
  className?: string;
}

export function Footer({ className }: FooterProps) {
  const { t } = useTranslation();
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  return (
    <footer className={cn(styles.footer, className)}>
      <Logo className={styles.logo} />
      <p className={styles.description}>{t('footer.text', { currentYear })}</p>
    </footer>
  );
}

import cn from 'classnames';
import { ReactNode } from 'react';

import styles from './Button.module.scss';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'micro'
  | 'microIcon'
  | 'breadcrumb'
  | 'icon'
  | 'nav'
  | 'toast'
  | 'highlight'
  | 'default';

interface ButtonCommonProps {
  name?: string;
  variant: ButtonVariant;
  alternate?: boolean;
  children?: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  className?: string;
}

interface DefaultButton extends ButtonCommonProps {
  type: 'button';
  onClick: () => void;
}

interface FormButton extends ButtonCommonProps {
  type: 'submit';
  onClick?: never;
}

type ButtonProps = FormButton | DefaultButton;

export function Button({
  name,
  children,
  variant,
  alternate,
  onClick,
  type,
  startIcon,
  endIcon,
  disabled,
  className
}: ButtonProps) {
  return (
    <button
      aria-label={name}
      className={cn(styles.button, styles[`${variant}`], className, {
        [styles[`${variant}Disabled`]]: disabled,
        [styles[`${variant}Alternate`]]: alternate,
        [styles[`${variant}AlternateDisabled`]]: alternate && disabled,
        [styles.iconOnly]: !children && !endIcon
      })}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-cy={name}
    >
      {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
      {children && <span>{children}</span>}
      {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
    </button>
  );
}

import cn from 'classnames';
import { ReactNode } from 'react';
import { ButtonLink } from './ButtonLink';
import { Title, TitleTag, TitleTheme } from './Title';

import styles from './Header.module.scss';

interface HeaderProps {
  children?: ReactNode;
  titleTag: string;
  titleText?: ReactNode;
  titleTheme?: ReactNode;
  descriptionText?: ReactNode;
  href?: string;
  breadcrumbText?: ReactNode;
  className?: string;
  childrenClassName?: string;
  shadow?: boolean;
}

export function Header({
  children,
  titleTag,
  titleText,
  titleTheme,
  descriptionText,
  breadcrumbText,
  href = '/',
  className,
  childrenClassName,
  shadow = true
}: HeaderProps) {
  return (
    <div
      aria-level={Number(titleTag.slice(1))}
      className={cn(styles.header, className)}
    >
      {href && (
        <ButtonLink
          variant="breadcrumb"
          className={styles.breadcrumb}
          href={href}
        >
          {breadcrumbText}
        </ButtonLink>
      )}
      <Title
        className={cn(styles.title, {
          [styles.shadow]: shadow
        })}
        titleTheme={titleTheme as TitleTheme}
        titleTag={titleTag as TitleTag}
      >
        {titleText}
      </Title>
      <p className={styles.description}>{descriptionText}</p>
      {children && (
        <div className={cn(styles.buttons, childrenClassName)}>{children}</div>
      )}
    </div>
  );
}

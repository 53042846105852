import { ReactNode } from 'react';
import styles from './Layout.module.scss';
import { Footer } from '../components/Footer';
import cn from 'classnames';

interface LayoutProps {
  children: ReactNode;
  className?: string;
}

export function Layout({ children, className }: LayoutProps) {
  return (
    <main className={styles.page}>
      <section className={cn(styles.section, className)}>{children}</section>
      <Footer className={className} />
    </main>
  );
}
